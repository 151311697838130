export const sizesOrder = [
  // keep in mind new ones might pop up that aren't in here
  'XXS',
  'XS',
  'S',
  'XS/S',
  'S/M',
  'M',
  'M/L',
  'L',
  'L/XL',
  'XL',
  'XL/XXL',
  'XXL',
  '44/46',
  '48/50',
  '52/54',
  '60',
  '70',
  '80',
  '90',
  '100',
  '110',
  '120',
  '130',
  '140',
  '150',
  '160',
  'One Size',
];

// eslint-disable-next-line
export const isTouchDevice = () => {
  if (typeof window === 'undefined') return null;

  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = query => {
    return window.matchMedia(query).matches;
  };

  // eslint-disable-next-line
  if ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch)) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
};

import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as TippyTooltip } from 'react-tippy';
import styled, { css } from 'styled-components';
import { isTouchDevice } from '../../helpers/isTouchDevice';
import { fonts } from '../../styles/typography';

// https://github.com/tvkhoa/react-tippy

export const Tooltip = props => {
  if (isTouchDevice()) return props.children;

  return (
    <ToolTipWrapper mb={props.mb} content={props.content}>
      <TippyTooltip
        html={<Tip>{props.content}</Tip>}
        delay={400}
        hideDelay={0}
        duration={0}
        animation="none"
        position="bottom"
        trigger="mouseenter"
        unmountHTMLWhenHide={false}
      >
        {props.children}
      </TippyTooltip>
    </ToolTipWrapper>
  );
};

const ToolTipWrapper = styled.div`
  display: inline;
  position: relative;

  ${({ mb }) =>
    mb &&
    css`
      margin-bottom: ${mb}px;
    `};
`;

const Tip = styled.span`
  ${fonts.NeoSansLight};
  cursor: auto;
  position: relative;
  color: #000;
  font-size: 12px;

  background-color: #fff;
  border-radius: 0;
  top: 15px;
  bottom: auto;
  border: 1px solid #e5e5e5;
  padding: 9px 14px;
  min-width: 0;
`;

Tooltip.defaultProps = {
  content: '',
  children: null,
  mb: 0
};
Tooltip.propTypes = {
  content: PropTypes.string,
  children: PropTypes.node,
  mb: PropTypes.number
};

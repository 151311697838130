import { ImagePlaceholder, TextPlaceholder } from '@depict-ai/react-ui';
import { PlaceholderTextContainer, Wrapper } from './styles';

const DepictProductCardSkeleton: React.FC = () => {
  return (
    <Wrapper>
      <div>
        <ImagePlaceholder aspectRatio={2 / 3} className="" />
      </div>
      <PlaceholderTextContainer>
        <TextPlaceholder width="150px" height="20px" className="" />
        <TextPlaceholder width="100%" height="70px" className="" />
        <TextPlaceholder width="70px" height="15px" className="" />
        <TextPlaceholder width="120px" height="15px" className="" />
      </PlaceholderTextContainer>
    </Wrapper>
  );
};

export default DepictProductCardSkeleton;

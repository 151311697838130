import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, maxBy } from 'lodash/fp';
import { ProductCardPrice } from '../ProductCardPrice/ProductCardPrice';

export const ProductPrice = props => {
  const { currentVariant } = props;
  const mostExpensiveSize = maxBy(
    size => parseInt(get(['price', 'sortingPrice'], size), 10),
    get('sizes', currentVariant)
  );

  if (get(['price', 'reduced'], mostExpensiveSize)) {
    return (
      <Fragment>
        <ProductCardPrice oldPrice>{get(['price', 'list'], mostExpensiveSize)}</ProductCardPrice>
        <ProductCardPrice>{get(['price', 'reduced'], mostExpensiveSize)}</ProductCardPrice>
      </Fragment>
    );
  }

  return <ProductCardPrice>{get(['price', 'list'], mostExpensiveSize)}</ProductCardPrice>;
};

ProductPrice.propTypes = {
  currentVariant: PropTypes.object.isRequired
};

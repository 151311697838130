import styled from 'styled-components';

export const PlaceholderTextContainer = styled.div`
  & > * {
    display: block;
    margin: 8px auto;
    & > * {
      display: block;
      margin-inline: auto;
    }
  }
`;

export const Wrapper = styled.div`
  &:first-of-type {
    margin-top: 12px;
  }
`;

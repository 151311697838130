import React from 'react';
import {
  Biodegradable,
  Bluesign,
  Circular,
  Recyclable,
  Recycled,
  Renewable,
  Reuse,
  ReuseGood,
  ReuseExcellent
} from '../components/Icon/reactIcons';

export const renderSustainabilitySvg = (id, width, height) => {
  switch (id) {
    case 'Biodegradable':
      return <Biodegradable width={width} height={height} />;
    case 'Bluesign':
      return <Bluesign width={width} height={height} />;
    case 'Circular':
      return <Circular width={width} height={height} />;
    case 'Recyclable':
      return <Recyclable width={width} height={height} />;
    case 'Recycled':
      return <Recycled width={width} height={height} />;
    case 'Renewable':
      return <Renewable width={width} height={height} />;
    case 'Reuse':
      return <Reuse width={width} heigth={height} />;
    case 'Good':
      return <ReuseGood width={width} heigth={height} />;
    case 'Excellent':
      return <ReuseExcellent width={width} heigth={height} />;
    default:
      return null;
  }
};

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { hex } from 'wcag-contrast';

export const RadioButton = styled.input.attrs({
  type: 'radio'
})`
  & + .checkout-gift__color-label {
    min-height: 20px;
  }

  &:disabled + label:before {
    background-color: #eee !important;
    cursor: not-allowed !important;
  }
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -999999px;
  }
  &:checked + label,
  &:not(:checked) + label {
    position: relative;
    padding: ${props => props.size / 8}px ${props => props.size + props.spacing}px 0 0;
    margin: 0 1px 10px 1px;
    cursor: pointer;

    ${props =>
      props.color &&
      hex(props.color, '#ffffff') <= 1.2 &&
      css`
        &:before {
          border: 1px solid ${props.theme.color.borderGrey};
        }
      `};

    @media screen and (max-width: 620px) {
      padding: 3px 28px 0 0;
    }
  }
  &:checked + label:before,
  &:not(:checked) + label:before,
  &:checked:not(:disabled) + label:after,
  &:not(:checked):not(:disabled) + label:after {
    content: '';
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    position: absolute;
    left: ${props => props.size / 4}px;
    top: ${props => props.size / 4}px;
    border-radius: 100%;

    @media screen and (max-width: 620px) {
      width: 20px;
      height: 20px;
      left: 5px;
      top: 5px;
    }
  }
  &:checked + label:before,
  &:not(:checked) + label:before {
    background-color: ${props => props.color};
  }
  &:checked:not(:disabled) + label:after,
  &:not(:checked):not(:disabled) + label:after {
    transition: opacity 250ms ease-out, transform 250ms ease-out;
    box-shadow: inset 0px 0px 0px 1px rgba(141, 141, 141, 1),
      inset 0px 0px 0px ${props => Math.floor(props.size / 5)}px rgba(255, 255, 255, 1);
  }
  &:not(:checked) + label:after {
    opacity: 0;
    transform: scale(1.2);
  }
  & + label:hover:after,
  &:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
`;

RadioButton.defaultProps = {
  size: 20,
  color: null,
  spacing: 6
};

RadioButton.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  spacing: PropTypes.number
};
